import "react-toastify/dist/ReactToastify.css";

import { GlobalStyle, Main } from "@styles/globalStyles";
import React, { ReactNode } from "react";

import Footer from "./footer";
import Header from "./header";
import { ToastContainer } from "react-toastify";
import { checkURLParams } from "@util/hooks";

interface Props {
  children: ReactNode;
}

function Layout(props: Props) {
  const { children } = props;

  checkURLParams();

  return (
    <div>
      <GlobalStyle />
      {/* <Header /> */}
      <Main>{children}</Main>
      <ToastContainer position="bottom-right" />

      {/* <Footer /> */}
    </div>
  );
}

export default Layout;
