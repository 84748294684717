import { GlobalQuiz, GlobalQuizQuestion, User } from "./types";
import { Maybe, SanitySection } from "@graphql-types";
import { devtools, persist } from "zustand/middleware";
import {
  useAddFortuneToQuiz,
  useAddGlobalQuizAnswer,
  useAddGoal,
  useAddTextInput,
  useClearQuiz,
} from "./logicHooks";

import create from "zustand";

interface State {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
  user?: User | null;
  currentSection?: SanitySection | undefined;
  setCurrentSection: (currentSection: SanitySection | undefined) => void;
  //Quizz
  globalQuiz?: GlobalQuiz[];
  setGlobalQuizz: (globalQuiz: GlobalQuiz[]) => void;
  setGlobalQuizzAnswer: (question: any, ref: Maybe<string> | undefined) => void;
  setGlobalQuizzFortuneCard: (fortuneCard: any, ref: Maybe<string> | undefined) => void;
  isPreview: boolean;
  setIsPreview: (isPreview: boolean) => void;
  clearGlobalQuizz: (ref: Maybe<string> | undefined) => void;
  globalPopupVisibility: boolean;
  setGlobalPopupVisibility: (globalPopupVisibility: boolean) => void;
  globalQuizOutcomes?: any[];
  setGlobalQuizOutcomes: (globalQuizOutcomes: any[]) => void;
  textInputs: any[];
  setTextInputs: (textInput: string, id: string) => void;
  socialPollAnswer?: any;
  setSocialPollAnswer: (doc: any) => void;
  personalityQuiz?: any;
  setPersonalityQuiz?: (answer: boolean, personalityType: string) => void;
  goals: any[];
  setGoals?: (
    goalGroup: Maybe<string> | undefined,
    fieldType: Maybe<string> | undefined,
    text: Maybe<string> | undefined,
  ) => void;
  userName?: string;
  setUserName?: (userName: string) => void;
}

const defaultState = {
  loggedIn: false,
  globalPopupVisibility: false,
  user: null,
  globalQuiz: [],
  isPreview: false,
  textInputs: [],
  goals: [],
};

const devtoolsInNonProd =
  process.env.GATSBY_NODE_ENV === "production" ? (store: any) => store : devtools;

export const useStore = create<State>(
  devtoolsInNonProd(
    persist<State>(
      (set, get) => ({
        ...defaultState,
        setLoggedIn: loggedIn => set({ loggedIn }),
        setGlobalPopupVisibility: globalPopupVisibility => set({ globalPopupVisibility }),
        setCurrentSection: (currentSection: SanitySection | undefined) => set({ currentSection }),
        setGlobalQuizz: globalQuiz => set({ globalQuiz: [...get().globalQuiz, ...globalQuiz] }),
        setIsPreview: isPreview => set({ isPreview }),
        setGlobalQuizzFortuneCard: (fortuneCard: any, ref: Maybe<string> | undefined) => {
          if (ref == null) return;
          const globalQuizArray = get().globalQuiz;
          const updatedQuizArray = useAddFortuneToQuiz(ref, fortuneCard, globalQuizArray);
          if (updatedQuizArray == null) return;
          set({ globalQuiz: updatedQuizArray });
          return "added";
        },
        setGlobalQuizzAnswer: (question: GlobalQuizQuestion, ref: Maybe<string> | undefined) => {
          if (ref == null) return;
          const globalQuizArray = get().globalQuiz;
          const updatedQuizArray = useAddGlobalQuizAnswer(ref, question, globalQuizArray);
          if (updatedQuizArray == null) return;
          set({ globalQuiz: updatedQuizArray });
          //total score
        },
        clearGlobalQuizz: (ref: Maybe<string> | undefined) => {
          if (ref == null) return;
          const globalQuizArray = get().globalQuiz;
          const resetQuiz = useClearQuiz(ref, globalQuizArray);
          if (resetQuiz == null) return;
          set({ globalQuiz: resetQuiz });
        },
        setGlobalQuizOutcomes: globalQuizOutcomes => set({ globalQuizOutcomes }),
        setTextInputs: (textInput: string, id: string) => {
          if (textInput == null) return;
          const updatedTextInputArray = useAddTextInput(get().textInputs, textInput, id);

          set({ textInputs: updatedTextInputArray });
        },
        setSocialPollAnswer: (doc: any) => {
          if (doc == null) return;
          set({ socialPollAnswer: doc });
        },
        setPersonalityQuiz: (answer: boolean, personalityType: string) => {
          const quiz = get().personalityQuiz;
          if (answer) {
            const res = quiz.personalityType;
          }
        },
        setGoals: (
          goalGroup: Maybe<string> | undefined,
          fieldType: Maybe<string> | undefined,
          text: Maybe<string> | undefined,
        ) => {
          if (goalGroup == null || fieldType == null || text == null) return;
          const goals = get().goals;
          const updatedArray = useAddGoal(goals, goalGroup, fieldType, text);
          if (updatedArray) {
            set({ goals: updatedArray });
          }
        },
        setUserName: (userName: string) => {
          if (userName == null) return;
          set({ userName: userName });
        },
      }),
      {
        name: "life101",
        getStorage: () => localStorage,
      },
    ),
  ),
);

const returnCumulativeScore = (questionArray: any) => {
  let totalScore = 0;

  questionArray.forEach(question => {
    const score = question.answer.score;

    if (score) {
      totalScore += parseInt(score);
    }
  });

  return totalScore;
};
