import { GlobalQuiz, GlobalQuizQuestion } from "./types";
import { Maybe, SanityGlobalQuizQuestion, SanitySocialPoll } from "@graphql-types";
import { useCreateDoc, useGetDoc, useSetDoc } from "../util/firebase";
import { useEffect, useState } from "react";

import { text } from "stream/consumers";

export const useAddFortuneToQuiz = (
  quizRef: string,
  fortuneCard: SanityGlobalQuizQuestion,
  globalQuizArray: GlobalQuiz[] | undefined,
) => {
  if (globalQuizArray == undefined) return;

  const globalQuizRef = globalQuizArray.find(quiz => quiz.id === quizRef);

  const filterCurrentFortune =
    globalQuizRef?.fortunes && globalQuizRef?.fortunes.filter(item => item.id !== fortuneCard._id);

  const fortuneObject = {
    id: fortuneCard._id,
    score: fortuneCard.score,
  };

  const fortuneArray = filterCurrentFortune
    ? [...filterCurrentFortune, fortuneObject]
    : [fortuneObject];

  const totalScore = useReturnCumulativeScore(
    globalQuizRef?.questions,
    fortuneArray,
    globalQuizRef?.initialScoreValue,
  );

  const updatedData = globalQuizArray.map(x =>
    x.id === globalQuizRef?.id ? { ...x, fortunes: fortuneArray, score: totalScore } : x,
  );

  return updatedData;
};

export const useAddGlobalQuizAnswer = (
  quizRef: string,
  question: any,
  globalQuizArray: GlobalQuiz[] | undefined,
) => {
  if (globalQuizArray == undefined) return;

  const globalQuizRef = globalQuizArray.find(quiz => quiz.id === quizRef);

  const filterCurrentQuestion =
    globalQuizRef?.questions &&
    globalQuizRef?.questions.filter(item => item._id !== question.question._id);

  const newQuestion = {
    _id: question.question._id,
    selectedAnswer: question.selectedAnswer,
  };

  const updatedQuestionArray = filterCurrentQuestion
    ? [...filterCurrentQuestion, newQuestion]
    : [newQuestion];

  const totalScore = useReturnCumulativeScore(
    updatedQuestionArray,
    globalQuizRef?.fortunes,
    globalQuizRef?.initialScoreValue,
  );

  const updatedData = globalQuizArray.map(x =>
    x.id === globalQuizRef?.id ? { ...x, questions: updatedQuestionArray, score: totalScore } : x,
  );

  return updatedData;
};

export const useReturnCumulativeScore = (
  questions?: any,
  fortunes?: any,
  initialScore?: number,
) => {
  if (questions == null && fortunes == null) return;

  let totalScore = initialScore ?? 0;

  questions?.map(question => {
    if (question.selectedAnswer && question.selectedAnswer.score) {
      totalScore = totalScore + question.selectedAnswer.score;
    }
  });

  fortunes?.map(fortune => {
    if (fortune && fortune.score) {
      totalScore = totalScore + fortune.score;
    }
  });

  return totalScore;
};

export const useClearQuiz = (quizRef: string, globalQuizArray: GlobalQuiz[] | undefined) => {
  if (globalQuizArray == undefined) return;
  const globalQuizRef = globalQuizArray.find(quiz => quiz.id === quizRef);

  if (globalQuizRef == undefined || globalQuizRef.score == null) return;

  const updatedData = globalQuizArray.map(x =>
    x.id === globalQuizRef?.id ? { ...x, questions: [], score: 0, fortunes: [] } : x,
  );

  return updatedData;
};

export const useAddTextInput = (textInputsArray: any[], textInput: string, id: string) => {
  const newTextInput = {
    text: textInput,
    id: id,
  };

  const find = textInputsArray.find(x => x.id == id);
  if (find) {
    const updatedData = textInputsArray.map(x => (x.id === id ? { ...newTextInput } : x));
    return updatedData;
  }

  // console.log(newTextInput);
  const newArray = textInputsArray.length > 0 ? [...textInputsArray, newTextInput] : [newTextInput];

  // const updatedData = newArray.map(x => (x.id === id ? { ...newTextInput } : x));
  // console.log(updatedData);

  // if (updatedData.length == 0) {
  //   return [newTextInput];
  // }

  // console.log({ updatedData });

  return newArray;
};

export const useGetTextInput = (textInputsArray: any[], id: string) => {
  const find = textInputsArray.find(x => x.id == id);
  return find;
};

export const useReturnOrSetupSocialPoll = async (socialPoll: SanitySocialPoll) => {
  if (socialPoll == null || socialPoll._id == null) return;

  const currentSocialPoll = await useGetDoc("social_polls", socialPoll._id);
  if (currentSocialPoll == undefined) {
    const answers = socialPoll.answers?.map(answer => {
      return {
        answerID: answer?._key,
        answerCount: 0,
        answer: answer?.answer,
      };
    });

    const newSocialPoll = {
      totalAnswers: 0,
      id: socialPoll._id,
      answers: answers,
    };

    const set = await useSetDoc("social_polls", newSocialPoll, socialPoll._id);
    return set;
  }

  return currentSocialPoll;
};

export const useUpdateSocialPoll = async (socialPoll: any, answer: string) => {
  if (socialPoll == null || answer == null) return;

  const updatedAnswerArray = socialPoll.answers.map(item => {
    if (item.answerID == answer) {
      return {
        answerID: item.answerID,
        answerCount: item.answerCount + 1,
        answer: item.answer,
      };
    }
    return item;
  });

  const updatedSocialPoll = {
    id: socialPoll.id,
    totalAnswers: socialPoll.totalAnswers + 1,
    answers: updatedAnswerArray,
  };

  const set = useSetDoc("social_polls", updatedSocialPoll, socialPoll.id);
  return set;
};

export const useReturnSocialPollPercentage = async (questionID: string, answerID: string) => {
  const res = await useGetDoc("social_polls", questionID);

  const answer = res.answers.find(item => item.answerID == answerID);
  const per = answer.answerCount / res.totalAnswers;
  return per * 100;
};

export const useAddGoal = (goals: any, goalGroup: string, fieldType: string, text: string) => {
  const findGroup = goals.find(item => item.groupID == goalGroup);
  const filter = goals.filter(item => item.groupID !== goalGroup);
  if (findGroup) {
    const findFields = findGroup.fields.filter(item => item.fieldType !== fieldType);

    const update = [
      ...findFields,
      {
        fieldType: fieldType,
        text: text,
      },
    ];

    return [
      ...filter,
      {
        groupID: goalGroup,
        fields: [...update],
      },
    ];
  }

  const updatedArray = [
    ...filter,
    {
      groupID: goalGroup,
      fields: [
        {
          fieldType: fieldType,
          text: text,
        },
      ],
    },
  ];

  return updatedArray;
};
