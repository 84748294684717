import firebase from "gatsby-plugin-firebase";
import storage from "gatsby-plugin-firebase";
import { toast } from "react-toastify";
import { FirebaseExternalProviderType, LoginProps } from "@util/types";

export const useCreateDoc = async (collection: string, data: object) => {
  const firestore = firebase.firestore();
  const docCol = firestore.collection(collection);

  const addDoc = await docCol.add(data).catch((error: any) => {
    if (error?.message) {
      toast.error(error.message);
      return;
    }
    toast.error("Something went wrong, please try again later.");
  });

  return addDoc?.id;
};

export const useSetDoc = async (collection: string, data: object, id: string) => {
  const firestore = firebase.firestore();
  const docRef = firestore.collection(collection).doc(id);

  return await docRef.set(data, { merge: true }).catch((error: any) => {
    if (error?.message) {
      toast.error(error.message);
      return;
    }
    toast.error("Something went wrong, please try again later.");
  });
};

export const useGetDoc = (collection: string, id: string) => {
  const firestore = firebase.firestore();
  const docRef = firestore.collection(collection).doc(id);

  return docRef
    .get()
    .then((doc: any) => {
      if (doc.exists) {
        const id = doc.id;
        const data = doc.data();

        return { id, ...data };
      }
    })
    .catch((error: any) => {
      console.log({ error });

      if (error?.message) {
        toast.error(error.message);
        return;
      }
      toast.error("Something went wrong, please try again later.");
    });
};

export const useUpdateDoc = (collection: string, id: string, data: object) => {
  const firestore = firebase.firestore();
  const docRef = firestore.collection(collection).doc(id);

  return docRef.update(data).catch((error: any) => {
    if (error?.message) {
      toast.error(error.message);
      return;
    }
    toast.error("Something went wrong, please try again later.");
  });
};

export const firestoreDocsArray = (
  docRef: firebase.firestore.Query<firebase.firestore.DocumentData>,
) => {
  return docRef.get().then(doc =>
    doc.docs.map(doc => {
      const id = doc.id;
      const data = doc.data();

      return { id, ...data } as any;
    }),
  );
};

export const useQueryCollection = (collection: string, query: string) => {
  const firestore = firebase.firestore();
  const docRef = firestore.collection(collection).where(query, "==", true);

  return firestoreDocsArray(docRef);
};

export const useQuerySocialPoll = async (questionID: string, answerID: string) => {
  const firestore = firebase.firestore();
  // const docRef = firestore
  //   .collection("social_polls")
  //   .where(questionID, "==", "questionID")
  //   .where(answerID, "==", "answerID");

  // return firestoreDocsArray(docRef);

  const collectionRef = firestore.collection("cities");
  const query = collectionRef.where("state", "==", "CA");
  const snapshot = await query.count().get();
  console.log(snapshot.data().count);
};

interface User {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
}

//createUserWithEmail
export const useCreateUserWithEmailAndPassword = async (data?: User | null) => {
  if (!data || !data.password) return;

  const uid = await firebase
    .auth()
    .createUserWithEmailAndPassword(data.email, data.password)
    .then((results: any) => {
      if (!results || !results.user) {
        toast.error("An error occurred while trying to log in");
        return;
      }
      return results.user.uid;
    })
    .catch((error: any) => {
      if (error?.message) {
        toast.error(error.message);
        return;
      }
      toast.error("Something went wrong, please try again later.");
    });

  if (!uid) return;
  delete data.password;

  return useGetDoc("users", uid);
};

//createOrSigninUserWithGoogle
export const useCreateUserWithExternalProvider = async (type: FirebaseExternalProviderType) => {
  const provider =
    type === "google"
      ? new firebase.auth.GoogleAuthProvider()
      : new firebase.auth.FacebookAuthProvider();

  const user = await firebase
    .auth()
    .signInWithPopup(provider)
    .then((results: any) => {
      console.log({ results });

      if (!results || !results.user) {
        toast.error("An error occurred while trying to log in");
        return;
      }
      const user = results.user;
      const nameArr = user.displayName.split(" ");
      return {
        id: user.uid,
        data: {
          email: user.email,
          firstName:
            user.firstName ?? nameArr.length > 1
              ? user.displayName.split(` ${nameArr[nameArr.length - 1]}`)[0]
              : user.displayName,
          lastName: user.lastName ?? nameArr.length > 1 ? nameArr[nameArr.length - 1] : "",
          createdAt: new Date().toISOString(),
        },
      };
    })
    .catch((error: any) => {
      console.log({ error });

      if (error?.message) {
        toast.error(error.message);
        return;
      }
      toast.error("Something went wrong, please try again later.");
    });

  if (!user?.id) return;
  console.log(user.id);

  const getUserData = await useGetDoc("users", user.id);
  console.log({ getUserData });

  if (getUserData != undefined) {
    return getUserData;
  }

  await useSetDoc("users", { ...user.data }, user.id);
  return useGetDoc("users", user.id);
};

//login
export const useSignInWithEmailAndPassword = async (data?: LoginProps | null) => {
  if (!data || !data.password) return;

  const uid = await firebase
    .auth()
    .signInWithEmailAndPassword(data.email, data.password)
    .then((results: any) => {
      if (!results || !results.user) {
        toast.error("An error occurred while trying to log in");
        return;
      }
      return results.user.uid;
    })
    .catch((error: any) => {
      if (error?.message) {
        toast.error(error.message);
        return;
      }
      toast.error("Something went wrong, please try again later.");
    });

  if (!uid) return;

  return useGetDoc("users", uid);
};

//passwordReset
export const useResetPassword = (email: string) => {
  if (!email) return false;

  return firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      return true;
    })
    .catch(error => {
      if (error?.message) {
        toast.error(error.message);
        return false;
      }
      toast.error("Something went wrong, please try again later.");
      return false;
    });
};

export const useUploadPhoto = async (file: string) => {
  const storage = firebase.storage();
  const storageRef = firebase.storage().ref(storage, `/files/${file.name}`);
  console.log({ storageRef });

  // const storageRef = ref(storage, `/files/${file.name}`)
  // const uploadTask = firebase.storage().uploadBytesResumable(storageRef, file);

  // 'file' comes from the Blob or File API
  // uploadBytes(storageRef, file).then((snapshot) => {
  //   console.log('Uploaded a blob or file!');
  // });
};
