import { LAPTOP_BREAKPOINT, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "./constants";
import {
  Maybe,
  SanityAudio,
  SanityGlobalQuizAnswer,
  SanityGlobalQuizQuestion,
  SanityGoalGroup,
  SanityHero,
  SanityPage,
} from "@graphql-types";
import { PersonalityScoreData, ScreenWidth } from "./types";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";

import { GlobalQuiz } from "@state/types";
import { isBrowser } from "./helper";
import { sanityClient } from "@lib/sanityClient";
import { toast } from "react-toastify";
import { useGetResults } from "./groqHooks";
import { useStore } from "@state/store";

const defaultScreenWidth = {
  isTabletWidth: false,
  isMobileWidth: false,
  isLaptopWidth: false,
};

// get previous const for comparsion
export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

// similar to use previous but will do an comparsion between prev & next prop
export function usePreviousCompare<T>(next: T, compare: (prev: T, next: T) => T) {
  const previousRef = useRef<T>(next);
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (previousRef.current == null) {
      return;
    }

    if (!isEqual && previousRef.current) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}

// check if component has been mounted
export function useHasMounted() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  if (!mounted) {
    return false;
  }

  return mounted;
}

// the same as useState but will store the value in localStorage
function useStorage<T>(key: string, defaultValue: T | (() => T), storage: Storage) {
  const [value, setValue] = useState(() => {
    const jsonValue = storage.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);

    if (defaultValue instanceof Function) {
      return defaultValue();
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (value === undefined) return storage.removeItem(key);
    storage.setItem(key, JSON.stringify(value));
  }, [key, value, storage]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
}

export function useLocalStorage<T>(key: string, defaultValue: T | (() => T)) {
  if (!isBrowser()) return;
  return useStorage(key, defaultValue, window.localStorage);
}

export function useSessionStorage<T>(key: string, defaultValue: T | (() => T)) {
  if (!isBrowser()) return;
  return useStorage(key, defaultValue, window.sessionStorage);
}

// event listener hook
export function useEventListener(
  eventName: string,
  handler: (args: any) => void,
  elementToListen?: any,
) {
  if (!isBrowser()) return;
  const element = elementToListen ?? window;

  const savedHandler = useRef<typeof handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    //@ts-ignore
    const eventListener = (event: any) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);

    return () => element.removeEventListener(eventName, eventListener);
  }, [eventName, element]);
}

// set dark dark mode
export function useDarkMode(refObject?: RefObject<any>) {
  if (!isBrowser()) {
    return;
  }
  const [enabled, setEnabled] = useState(false);
  const element = refObject?.current ?? window.document.body;

  useEffect(() => {
    const className = "dark-mode";

    if (element) {
      if (enabled) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    }
  }, [enabled]);

  return [enabled, setEnabled];
}

// console logs the state when it gets updated
export function useUpdateLogger(value: any) {
  useEffect(() => {}, [value]);
}

// changes the boolean value to it's opposite value
export function useToggle(initialState = false): [boolean, () => void] {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(state => !state), []);

  return [state, toggle];
}

// timeout hook, returns reset and clear function
export function useTimeout(callback: (args?: any) => void, delay: number) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  useEffect(() => {
    return () => clear();
  }, []);

  return { reset, clear };
}

// debounce hook - run a callback after a certain delay
export function useDebounce(callback: (args?: any) => void, delay: number, dependencies: any[]) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, []);
}

export function useArray(defaultValue: any[]) {
  const [array, setArray] = useState(defaultValue);

  const push = (element: any) => {
    setArray(a => [...a, element]);
  };

  const filter = (callback: (args?: any) => void) => {
    setArray(a => a.filter(callback));
  };

  const update = (index: number, newElement: any) => {
    setArray(a => [...a.slice(0, index), newElement, ...a.slice(index + 1, a.length)]);
  };

  const remove = (index: number) => {
    setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  };

  const clear = () => {
    setArray([]);
  };

  return { array, set: setArray, push, filter, update, remove, clear };
}

// counts the number of re-renders
export function useRenderCount() {
  const count = useRef(1);
  useEffect(() => {
    count.current++;
  }, []);
  return count.current;
}

// media query hook
export default function useMediaQuery(mediaQuery: string) {
  const [isMatch, setIsMatch] = useState(false);
  const [mediaQueryList, setMediaQueryList] = useState<MediaQueryList | null>(null);

  useEffect(() => {
    if (!isBrowser()) return;
    const list = window.matchMedia(mediaQuery);
    setMediaQueryList(list);
    setIsMatch(list.matches);
  }, [mediaQuery]);

  useEventListener("change", e => setIsMatch(e.matches), mediaQueryList);

  return isMatch;
}

// checks screen width
export function useCheckScreenWidth(): ScreenWidth {
  if (!isBrowser()) {
    return defaultScreenWidth;
  }
  const [screenWidth, setScreenWidth] = useState(defaultScreenWidth);
  const hasMounted = useHasMounted();

  const checkScreenWidth = () => {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
        isTabletWidth: true,
        isMobileWidth: true,
      });
      return;
    }
    if (window.innerWidth <= TABLET_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
        isTabletWidth: true,
      });
      return;
    }
    if (window.innerWidth <= LAPTOP_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
      });
      return;
    }
    if (window.innerWidth > LAPTOP_BREAKPOINT) {
      setScreenWidth(defaultScreenWidth);
      return;
    }
  };

  useEventListener("resize", checkScreenWidth);

  useEffect(() => {
    checkScreenWidth();
  }, []);

  useEffect(() => {
    if (hasMounted) {
      checkScreenWidth();
    }
  }, [hasMounted]);

  return screenWidth;
}

export const useConditionalVisibility = (
  question: SanityGlobalQuizQuestion,
  quizRef: Maybe<string> | undefined,
) => {
  if (question == null) return;

  const { globalQuiz } = useStore();
  if (globalQuiz == null) return;
  const globalQuizRef = globalQuiz.find(quiz => quiz.id === quizRef);

  const answersArray = question.answers?.map(answer => {
    const condtions = answer?.conditionalVisibility;

    //map conditional visibility

    let shouldDisplay = false;
    if (condtions && condtions.length) {
      //OR

      condtions.map(condition => {
        //now map references array

        if (condition?.references?.length == 0) return;
        // AND
        let shouldShowArray = [];

        condition?.references?.map(reference => {
          //find each conditionalQuestion
          const conditionalQuestion =
            globalQuizRef &&
            globalQuizRef.questions?.find(
              questions => questions._id == reference?.selectedQuestionKey,
            );

          if (conditionalQuestion?.selectedAnswer._key == reference?.selectedAnswerKey) {
            // shouldDisplay = true;
            shouldShowArray.push(true);
          }
        });
        if (shouldShowArray.length == condition?.references?.length) {
          return (shouldDisplay = true);
        }
      });

      return shouldDisplay ? answer : null;
    }

    return answer;
  });

  return answersArray;
};

export const useConditionalFortuneDestination = (
  quizRef: Maybe<string> | undefined,
  conditionalDestinations: any,
  globalQuiz?: GlobalQuiz[],
) => {
  if (globalQuiz == null) return;

  const globalQuizRef = globalQuiz.find(quiz => quiz.id === quizRef);
  let destinationPage: Maybe<SanityPage> | undefined = undefined;
  let shouldNavigate = false;

  conditionalDestinations.map(destination => {
    if (destination == null) return;
    //Map references
    destinationPage = destination.destination;
    let shouldShowArray = [];

    destination?.references?.map(reference => {
      const conditionalQuestion =
        globalQuizRef &&
        globalQuizRef.questions?.find(questions => questions._id == reference?.selectedQuestionKey);

      if (conditionalQuestion?.selectedAnswer._key == reference?.selectedAnswerKey) {
        shouldShowArray.push(true);
      }
    });
    if (shouldShowArray.length == destination?.references?.length) {
      return (shouldNavigate = true);
    }
  });

  return shouldNavigate ? destinationPage : null;
};

export const useConditionalDestination = (
  quizRef: Maybe<string> | undefined,
  answer: SanityGlobalQuizAnswer,
  globalQuiz?: GlobalQuiz[],
) => {
  if (globalQuiz == null) return;

  const globalQuizRef = globalQuiz.find(quiz => quiz.id === quizRef);
  if (answer.conditionalDestinations == null || answer.conditionalDestinations.length == 0) {
    if (answer.destinationPage) return answer.destinationPage;

    return null;
  }

  let destinationPage: Maybe<SanityPage> | undefined = undefined;
  let shouldNavigate = false;

  answer.conditionalDestinations.map(destination => {
    if (destination == null) return;
    //Map references
    destinationPage = destination.destination;
    let shouldShowArray = [];
    destination?.references?.map(reference => {
      const conditionalQuestion =
        globalQuizRef &&
        globalQuizRef.questions?.find(questions => questions._id == reference?.selectedQuestionKey);

      if (conditionalQuestion?.selectedAnswer._key == reference?.selectedAnswerKey) {
        shouldShowArray.push(true);
      }
    });

    if (shouldShowArray.length == destination?.references?.length) {
      return (shouldNavigate = true);
    }
  });

  return shouldNavigate ? destinationPage : null;
};

const countPassFail = arr =>
  arr.reduce((acc, curr) => acc + (curr.selectedAnswer.passFail === true ? 1 : 0), 0);

export const useResultsPage = (
  results: any[] | undefined,
  quizRef: Maybe<string> | undefined,
  globalQuiz?: GlobalQuiz[],
) => {
  if (results == null || globalQuiz == null) return;
  const globalQuizRef = globalQuiz.find(quiz => quiz.id === quizRef);

  //filter outcomes
  if (results.length == 0) {
    toast.error("No Result pages have been setup for this quiz");
    return;
  }
  const selectedOutcomes = results.find(item => item.id == quizRef);

  const outcomes = selectedOutcomes.outcomes;

  const passFailRatio =
    globalQuizRef?.scoringOption == "passFail" ? countPassFail(globalQuizRef?.questions) : "not";

  const currentScore =
    globalQuizRef?.scoringOption == "passFail" ? passFailRatio : globalQuizRef?.score;

  if (currentScore == null) {
    toast.error("The user currently has no score and has answered no questions");
    return;
  }

  let destinationPage: Maybe<SanityPage> | undefined = undefined;

  //setup conditions
  outcomes.map((outcome: any) => {
    if (outcome == null) return;

    switch (outcome.scoreOutcome) {
      case "above":
        if (currentScore > outcome.minimumScore && destinationPage == undefined) {
          destinationPage = outcome.outcomePage;
          return;
        }

        break;

      case "below":
        if (currentScore < outcome.maximumScore && destinationPage == undefined) {
          destinationPage = outcome.outcomePage;
          return;
        }

        break;

      case "range":
        if (
          currentScore <= outcome.rangeTop &&
          currentScore >= outcome.rangeBottom &&
          destinationPage == undefined
        ) {
          destinationPage = outcome.outcomePage;
          return;
        }
        break;

      case "score":
        if (currentScore === outcome.fixedScore && destinationPage == undefined) {
          destinationPage = outcome.outcomePage;
          return;
        }
        break;

      default:
        break;
    }

    return destinationPage;
  });

  return destinationPage;
};

export const useDragDropLength = (items: any) => {
  const [length, setLength] = useState(0);

  useEffect(() => {
    let l = 0;
    items.map(item => {
      if (item?.assets) {
        l = l += item.assets.length;
      }
    });
    setLength(l);
  }, []);

  useEffect(() => {
    console.log({ length });
  }, [length]);

  return length;
};

export const useAudio = (
  data: Maybe<SanityAudio> | undefined,
  autoPlay?: boolean,
  togglePlay?: boolean,
) => {
  if (data == null) return;
  const url = data.audioFile?.asset?.url;
  if (url == null) return;

  const [audioFile] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => {
    console.log("toggled");
    setPlaying(!playing);
  };

  useEffect(() => {
    console.log(playing);

    playing ? audioFile.play() : audioFile.pause();
  }, [playing]);

  useEffect(() => {
    audioFile.addEventListener("ended", () => setPlaying(false));
    return () => {
      audioFile.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  useEffect(() => {
    console.log({ audioFile });
    if (audioFile) {
      if (data?.timerDelay) {
        setTimeout(toggle, data?.timerDelay * 1000);
      }
    }
  }, [audioFile]);

  return [playing, toggle, audioFile];
};

export const useGetGoalsResultData = () => {
  const [goalsTableData, setGoalsTableData] = useState<SanityGoalGroup[]>();

  const fetchData = async () => {
    const fetch = await sanityClient.fetch(`*[_type == "goalGroup"]`);
    setGoalsTableData(fetch);
    return fetch;
  };

  useEffect(() => {
    if (!goalsTableData) {
      fetchData();
    }
  }, []);

  return goalsTableData;
};

export const audio = isBrowser() && new Audio();

export const playAudio = (audioURL: string, timerDelay: Maybe<number> | undefined) => {
  audio.pause();
  audio.src = audioURL;

  if (timerDelay) {
    setTimeout(() => {
      audio.play();
    }, timerDelay * 1000);
  } else {
    audio.play();
  }
};

export const stopAudio = () => {
  audio.pause();
  audio.currentTime = 0;
};

export const checkURLParams = () => {
  if (!isBrowser()) return;
  const params = new URLSearchParams(window.location.search);
  const usernameParam = params.get("username");

  const { userName, setUserName } = useStore();

  useEffect(() => {
    if (userName == null && usernameParam) {
      setUserName && setUserName(usernameParam);
    }
  }, []);

  return;
};

// Personality quiz scoring
export const usePersonalityQuiz = () => {
  const [scoreState, setScoreState] = useState<PersonalityScoreData[]>([]);
  const [resultObject, setResultObject] = useState({});

  const update = (scoreObject: any) => {
    console.log("im here", scoreObject);

    if (scoreState.length == 0) {
      setScoreState([scoreObject]);
      return;
    }
    const filteredArray = scoreState.filter(item => item.id !== scoreObject.id);
    console.log(filteredArray);
    setScoreState([...filteredArray, scoreObject]);
  };

  const calculateScore = () => {
    const result = {};

    scoreState.forEach(item => {
      if (item.score === true) {
        if (!result[item.personalityType]) {
          result[item.personalityType] = [];
        }
        result[item.personalityType].push(item);
      }
    });

    console.log(result);

    if (result) {
      setResultObject(result);
    }
  };

  useEffect(() => {
    console.log("Score", scoreState);
  }, [scoreState]);

  return { scoreState, update, calculateScore, resultObject };
};

// Drag and drop hooks

export const dragDropHandler = (data: any) => {
  const [dropped, setDropped] = useState<boolean[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedModalContent, setSelectedModalContent] = useState<SanityHero>();
  const [dragDropGroups, setDragDropGroups] = useState([]);
  const [touchItem, setTouchItem] = useState();

  const handleDragStart = (item: any) => {
    item.dataTransfer && item.dataTransfer.setData("item", item.target.id);
    console.log(item.target);

    setTouchItem(item.target);
  };

  var drg, drgT, drgL, drgB, drgR;

  const handleTouchDrag = (evt: any) => {
    if (touchItem) {
      touchItem.style.position = "absolute";
      touchItem.style.left = evt.touches[0].clientX - touchItem.clientWidth / 2;
      touchItem.style.top = evt.touches[0].clientY - touchItem.clientHeight / 2;

      drg = touchItem.getBoundingClientRect();
      drgT = drg.top;
      drgB = drg.bottom;
      drgL = drg.left;
      drgR = drg.right;
    } else {
      evt.preventDefault();
    }
  };

  const drop = (item: any, parentID: string) => {
    item.preventDefault();
    const data = item.dataTransfer.getData("item");
    item.target.appendChild(document.getElementById(data));

    const success = Boolean(data == parentID);
    updateDroppedAmount(success);
  };

  const allowDrop = (item: any) => {
    item.preventDefault();
  };

  const updateDroppedAmount = (result: boolean) => {
    const length = dragDropGroups?.length;
    if (length == null) return;

    const updated = dropped ? [...dropped, result] : [result];

    setDropped(updated);

    if (dropped.length == length - 1) {
      const resultedArray = dropped.filter(item => item == true);
      if (resultedArray && resultedArray.length == length - 1) {
        setSelectedModalContent(data.successPopup);
        setIsVisible(true);
      }
      if (resultedArray && resultedArray.length < length - 1 && resultedArray.length !== 0) {
        setSelectedModalContent(data.partialPopup);
        setIsVisible(true);
      }
      if (resultedArray && resultedArray.length == 0) {
        setSelectedModalContent(data.errorPopup);
        setIsVisible(true);
      }
    }
  };

  return {
    handleDragStart,
    drop,
    allowDrop,
    setDragDropGroups,
    selectedModalContent,
    isVisible,
    setIsVisible,
    handleTouchDrag,
  };
};
