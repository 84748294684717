import { fontSizes } from "./constants";

export const isBrowser = () => typeof window !== "undefined";

export function arraysEquality(array1: any[], array2: any[]) {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

export function objectEquality(object1: any, object2: any) {
  return Object.keys(object1).every(key => object1[key] === object2[key]);
}

export function splitArrayIntoChunks(array: any[], split: number) {
  const chunks = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / split);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return chunks;
}

export const em = (fontSize: number, baseSize: number = fontSizes.p.default) => {
  return `${fontSize / baseSize}em`;
};

export const emailValidator = (str: string) => {
  if (!str) return false;

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(str);
};

export const cleanedVimeoURL = (url: string) => {
  return `${replaceString(
    "https://vimeo.com/",
    "https://player.vimeo.com/video/",
    url,
  )}?background=0&autoplay=1&loop=0&byline=0&title=0&volume=1`;
};

function replaceString(oldS: string, newS: string, fullS: string) {
  return fullS.split(oldS).join(newS);
}

export function shuffleArray(array: any) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
